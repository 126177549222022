export const themeOverrides = {
    common: {
      fontSize: '12px',
      fontSizeSmall: '12px',
      fontFamily: 'Roboto',
      primaryColor: "#D5D5D5FF"
    },
    Button: {
      fontSize: '12px',
      fontSizeTiny: '12px',
      fontSizeSmall: '12px',
    },
    Descriptions: {
      fontSize: '12px',
      fontSizeSmall: '12px',
      lineHeight: 1.2,
    },
    Drawer: {
      bodyPadding: '16px 10px',
      fontSize: '12px',
    },
    Form: {
      blankHeightSmall: '38px',
      fontSize: '12px',
      labelFontSizeLeftSmall: '12px',
    },
    Menu: {
      itemHeight: '22px',
    }
  }