import { dpi2dpm } from "."

export const DEFAULT_FABRIC_WIDTH = 59.42715072631836

export const DEFAULT_FABRIC_HEIGHT = 78.69263458251953

export const DEFAULT_DPM = {
    x: dpi2dpm(96),
    y: dpi2dpm(96)
}

export const DEFAULT_DENSITY = {
    h: 64, //横密
    v: 46,//直密
}