<script lang="ts" setup>
import { useStyle3d } from './style3d.ts';
import type { Style3dOptions } from './typing.ts';
import { watch } from 'vue';

const props = defineProps<{
  src: string,
  options: Style3dOptions
}>();

const emit = defineEmits<{
  loaded: []
  ready: [ready: boolean]
  error: []
}>();

const { initStyle3d, isReady } = useStyle3d();

watch(() => isReady.value, (v) => {
  emit('ready', v);
});

function create3DViewer() {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = props.src;
  s.async = true;
  s.onload = async () => {
    await initStyle3d({
      ...props.options
    });
    emit('loaded');
  };
  s.onerror = () => {
    emit('error');
  };
  document.body.appendChild(s);
}

create3DViewer();
</script>

<template>
  <div style="width: 100%; height: 100%;" id="style3d-con" @dragover.prevent></div>
</template>
<style scoped>
</style>