/**
 * 像素转毫米
 * @param dpm
 * @param val 像素值
 * @returns {number}
 */
export function getMMNum(dpm: number, val: number): number | undefined {
  if (!dpm || !val) {
    return undefined;
  }
  // 像素/dpm(像素每米)*1000 =毫米
  return (val / dpm) * 1000;
}

// Conversion Factor: 1 dpi = 39.37 dpm = 0.03937 dpmm = 1 ppi
export function dpi2dpm(dpi: number) {
  return dpi * 39.37
}

// Conversion Factor: 1 dpm = 0.001 dpmm = 0.0254 dpi = 0.0254 ppi
export function dpm2dpi(dpm: number) {
  return dpm * 0.0254
}