// @ts-nocheck
import type { Material, MatrixInfo, Shape, UVInfo } from './typing.ts';

//矩阵*
function matrixMultiply(A: number[][], B: number[][]) {
  var rowsA = A.length;
  var colsA = A[0].length;
  var rowsB = B.length;
  var colsB = B[0].length;

  if (colsA !== rowsB) {
    throw new Error('矩阵尺寸不匹配，无法相乘。');
  }

  var result = new Array(rowsA);
  for (var i = 0; i < rowsA; i++) {
    result[i] = new Array(colsB);
    for (var j = 0; j < colsB; j++) {
      result[i][j] = 0;
      for (var k = 0; k < colsA; k++) {
        result[i][j] += A[i][k] * B[k][j];
      }
    }
  }
  return result;
}

//数组转矩阵
function convertArrayToMatrix(array: number[], rows: number, columns: number) {
  var matrix = [];
  for (var i = 0; i < array.length; i += columns) {
    matrix.push(array.slice(i, i + columns));
  }
  return matrix;
}

//点矩阵补全
function completePoint(point: number[]) {
  return [[...point, 1]];
}

//将n个不区分的数据转成点集
function splitPoint(data: number[]) {
  var points = [];

  for (var i = 0; i < data.length; i += 2) {
    var x = data[i];
    var y = data[i + 1];
    points.push([x, y]);
  }
  return points;
}

//查找点集中外接矩形的最小点
function findMinXY(points: number[][]) {
  return points.reduce(
    (min, [x, y]) => ({
      minX: Math.min(min.minX, x),
      minY: Math.min(min.minY, y)
    }),
    { minX: Infinity, minY: Infinity }
  );
}

//将平移矩阵的平移量做调整
function translateOffsetMatrix(matrix: number[], dx: number, dy: number) {
  if (matrix.length !== 9) {
    throw new Error('Input matrix should have 9 elements.');
  }
  const result = matrix.slice(); // Copy the input matrix
  // Apply translation to the 3rd column (x) and 6th column (y)
  result[6] += dx;
  result[7] += dy;

  return result;
}

function releaseUvInfo(uvInfo: UVInfo) {
  for (var clothId in uvInfo) {
    var uvItem = uvInfo[clothId];
    for (var orgItem in uvItem.originUv) {
      uvItem.originUv[orgItem].length = 0;
    }
  }
}

function releaseMatrixInfo(maxtrixInfo: MatrixInfo) {
  for (var clothId in maxtrixInfo) {
    var matItem = maxtrixInfo[clothId];
    for (var orgItem in matItem.geometryFabricMatrix) {
      matItem.geometryFabricMatrix[orgItem].length = 0;
    }
  }
}

function handleUvAndMatrix(
  shapes: Shape[],
  materialList: Material[],
  uvInfo: UVInfo,
  matrixInfo: MatrixInfo
): MatrixInfo {
  //查找有调整的 数据的 id
  for (let i = 0; i < shapes.length; i++) {
    const item = shapes[i];
    {
      //console.log('  loop shape & before filter ')
      const materials = materialList.filter((v) => v.name === item.piece);
      //console.log('    after filter ')
      if (materials && materials.length > 0) {
        //根据ID 遍历 uv
        //找到对应的 原始uv
        //console.log('      start loop uv info ')
        for (var clothId in uvInfo) {
          var uvItem = uvInfo[clothId];
          //console.log('        start loop origin uv ')
          for (var orgItem in uvItem.originUv) {
            //console.log('          filter material id ')
            //console.log('mateial info : ',materials)
            const materialId: string = `${materials[0].id}`;
            if (orgItem.startsWith(materialId)) {
              if (clothId in matrixInfo) {
                //console.log('            cloth id in matrix info ')
                //根据clothId 找matrix
                var clothMatrix = matrixInfo[clothId];
                var geometryMatrix = clothMatrix.geometryFabricMatrix;
                if (orgItem in geometryMatrix) {
                  //console.log('              orgItem in geometryMatrix ')
                  //计算用matrix
                  var arMatrix = geometryMatrix[orgItem];
                  var calcMatrix = convertArrayToMatrix(arMatrix, 3, 3);
                  var uvData = uvItem.originUv[orgItem];
                  //遍历uvData 补全点 并计算
                  var points = splitPoint(uvData);
                  //获取所有的点
                  //console.log('                map points ')
                  var transformedPoints = points.map((point) => {
                    var completedPoint = completePoint(point);
                    var transformedPoint = matrixMultiply(completedPoint, calcMatrix);
                    return transformedPoint[0];
                  });
                  //console.log('                  to find minxy ')
                  //然后求出外接矩形最小点
                  var minXY = findMinXY(transformedPoints);

                  //console.log('                    update matrix ')
                  //更新矩阵 （同ID 的所有
                  for (var materixKey in geometryMatrix) {
                    if (materixKey.startsWith(materialId)) {
                      //console.log('minxy : ',minXY)
                      geometryMatrix[materixKey] = translateOffsetMatrix(
                        arMatrix,
                        0 - minXY.minX,
                        0 - minXY.minY
                      );
                    }
                  }
                  break;
                }
              }
            }
          }
        }
      }
    }
  }
  return matrixInfo;
}

export {
  releaseUvInfo,
  releaseMatrixInfo,
  handleUvAndMatrix
};